import React from "react";
import { Normalize } from "styled-normalize";
import GlobalStyles from "../styles/globalStyles";

// Components
import { DefaultSEO } from "./default-seo";

const Layout = ({ children }) => (
  <>
    <Normalize />
    <GlobalStyles />
    <DefaultSEO />
    <main>{children}</main>
  </>
);

export default Layout;
