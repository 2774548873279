import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'DM Sans', sans-serif;
    font-size: 19px;
    line-height: 23px;
    letter-spacing: 0.1px;

    font-weight: normal;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -webkit-tap-highlight-color: transparent;

    color: #0a2a2f;
    background-color: #0a2a2f;

    @media (max-width: 500px) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  video,
  img {
    width: 100%;
    display: block;
  }

  /*--------------------------------------------------------------
    ## Full Page Plugin
    --------------------------------------------------------------*/

  // .fp-scroller{
  //   overflow:hidden;
  // }

  // .fp-tableCell{
  //   padding: 0;
  //   vertical-align: top !important;
  // }


  /*--------------------------------------------------------------
    ## Links
    --------------------------------------------------------------*/
  a {
    color: #0a2a2f;
    text-decoration: none;

    cursor: pointer;
    transition: 150ms color ease;
  }

  a:visited {
    color: #0a2a2f;
  }

  a:hover,
  a:focus,
  a:active {
    color: #0a2a2f;
  }

  a:hover,
  a:active,
  a:focus {
    outline: 0;
  }

  h1, h2 {
    font-weight: 500;
    line-height: initial;

    margin: 0;
  }

  h1 {
    font-size: 30px;
  }


  /*--------------------------------------------------------------
    ## Button
    --------------------------------------------------------------*/

    button {
      border:0;
      padding: 0;

      cursor: pointer;
      -webkit-appearance: none !important;

      background: #fff;

      &:focus{
        outline: 0;
      }
    }



  /*--------------------------------------------------------------
    ## Default Placeholders
    --------------------------------------------------------------*/

  ::-webkit-input-placeholder {
    color:  #0a2a2f;
  }
  ::-moz-placeholder {
    color:  #0a2a2f;
  }
  :-ms-input-placeholder {
    color:  #0a2a2f;
  }
  :-moz-placeholder {
    color:  #0a2a2f;
  }

`;

export default GlobalStyle;
